import { tr } from "@/utils/translation";
import DateTimeOffset from "@/models/DateTimeOffset.model";
import { parseISO } from "date-fns";
import { clamp, evenRound } from "@/utils/math";

export default class BaseActivityResult {
  constructor(result) {
    this.id = result.id;
    this.sessionId = result.sessionId || null;
    this.started = result.started || null;
    this.startOffset = new DateTimeOffset(result.startOffset);
    // We mimic c# default rounding behavior and round the midpoint to the closest even number
    this.score =
      Math.round(evenRound(clamp(result.score, 0.0, 1.0), 2) * 100) || 0.0;
    this.level = result.level || 0;
    this.compensationDuration = result.compensationDuration || 0.0;
    this.assists = result.assists || "none"; // Permits to get/set an empty value
    this.challenges = result.challenges || "none";
    this.activeSide = result.activeSide;
  }

  /**
   * Returns the translated assist
   */
  translatedAssists() {
    if (!this.assists) {
      return tr("Common.Activity.Assists.none");
    }
    return tr(`Common.Activity.Assists.${this.assists}`);
  }

  /**
   * Returns the translated challenge
   */
  translatedChallenges() {
    if (!this.challenges) {
      return tr("Common.Activity.Challenges.none");
    }
    return tr(`Common.Activity.Challenges.${this.challenges}`);
  }

  /**
   * Returns the translated side
   */
  translatedActiveSide() {
    if (!this.activeSide) {
      return "--";
    }
    return tr(`Common.Activity.Sides.${this.activeSide.toLowerCase()}`);
  }

  get StartedDate() {
    return this.started ? parseISO(this.started) : null;
  }
}
