<template>
  <div class="relative block">
    <slot name="before"></slot>
    <base-label
      :label="label"
      :labelClasses="labelClasses"
      :disabled="disabled"
      :required="required"
      :tooltip="tooltip"
    />
    <base-read-only-field
      v-if="isReadOnly"
      :value="localValue"
      :tooltip="readOnlyTooltip"
      :read-only-classes="readOnlyClasses"
    />
    <input
      v-else
      ref="base-input"
      :type="type"
      :class="[
        'form-input block min-w-48 w-full',
        inputClasses,
        anyError ? 'form-input-error' : '',
      ]"
      :style="inputStyle"
      :placeholder="disabled ? '' : placeholder"
      :disabled="disabled"
      v-model.trim="localValue"
      @input="onInput"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      @animationstart="animationStart"
      @animationend="animationEnd"
      @focusout="focusout"
      :maxlength="maxLength"
    />
    <slot name="bubble"></slot>
    <slot name="after"></slot>
  </div>
</template>

<script>
import BaseLabel from "@/components/ui/BaseLabel.vue";
import BaseReadOnlyField from "@/components/ui/display-fields/BaseReadOnlyField.vue";

export default {
  components: { BaseLabel, BaseReadOnlyField },
  data() {
    return {
      localValue: this.value,
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      required: false,
      default: "",
    },
    inputStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
    readOnlyClasses: {
      type: String,
      required: false,
    },
    labelClasses: {
      type: String,
      default: "",
    },
    maxLength: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    anyError: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    readOnlyTooltip: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.localValue);
    },
    onChange() {
      this.$emit("change", this.localValue);
    },
    onFocus() {
      this.$emit("focus");
    },
    onBlur() {
      this.$emit("blur");
    },
    animationStart(event) {
      this.$emit("animationstart", event);
    },
    animationEnd(event) {
      this.$emit("animationend", event);
    },
    focusout(event) {
      this.$emit("focusout", event);
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
